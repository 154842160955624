import { inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';

import {
  LocalStorageService,
  SessionStorageService
} from '@services/abstract-storage.service';
import { D_ID_KEY, USER_ID_KEY, USER_TOKEN_KEY } from '@utils/storage.utils';
import { environment } from '@env/environment';
import { EnvironmentTargetEnum } from '@models/enviroment.model';
import { AuthUserModel } from '@models/auth.model';
import md5 from 'md5';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  #localStorageService = inject(LocalStorageService);
  #sessionStorageService = inject(SessionStorageService);
  #cookieService = inject(CookieService);
  decodedUserAccount: AuthUserModel | null;
  #rememberKey = '_remember';
  rememberDate?: Date;

  constructor() {
    AuthService.setCompressedToken();
  }

  setDeviceHash(hash: string, jwtToken: string): void {
    const expires: Date = jwtHelper.getTokenExpirationDate(jwtToken);
    const path = '/';
    const isSecure = environment.target === EnvironmentTargetEnum.Production;
    const domain = isSecure ? `.${environment.interiorHost}` : null;
    const sameSite = 'Strict'; // 'Lax' | 'None' | 'Strict';

    if (this.#cookieService.check(D_ID_KEY)) {
      this.#cookieService.delete(D_ID_KEY, path, domain);
    }

    this.#cookieService.set(
      D_ID_KEY,
      hash,
      expires,
      path,
      domain,
      isSecure,
      sameSite
    );
  }

  getDeviceHash(): string {
    return this.#cookieService.get(D_ID_KEY);
  }

  setRemember() {
    this.rememberDate = new Date();
    this.#localStorageService?.setItem(
      this.#rememberKey,
      this.rememberDate.toString()
    );
  }

  logIn(jwtToken: string): void {
    this.#localStorageService.setItem(USER_TOKEN_KEY, jwtToken);
    this.decodeUserAccount();
    AuthService.setCompressedToken();
  }

  getToken(): string | null {
    const token = this.#cookieService.get(USER_TOKEN_KEY);

    // migrate from old implementation
    if (token) {
      this.#localStorageService.setItem(USER_TOKEN_KEY, token);
      this.#cookieService.delete(USER_TOKEN_KEY, '/my');
      this.#cookieService.delete(USER_TOKEN_KEY, '/');
    }

    return this.#localStorageService.getItem(USER_TOKEN_KEY);
  }

  decodeUserAccount() {
    const token = this.getToken();
    if (!token) {
      return null;
    }

    try {
      this.decodedUserAccount = jwtHelper.decodeToken(token);
    } catch {}
  }

  removeTokens() {
    this.#localStorageService.removeItem(USER_TOKEN_KEY);
    this.#localStorageService.removeItem(this.#rememberKey);
    this.#sessionStorageService.removeItem(USER_ID_KEY);
    AuthService.removeCompressedToken();
  }

  removeDeviceHash(): void {
    const path = '/';
    const domain =
      environment.target === EnvironmentTargetEnum.Development
        ? null
        : `.${environment.interiorHost}`;
    const secure = environment.target !== EnvironmentTargetEnum.Development;

    this.#cookieService.delete(D_ID_KEY, path, domain, secure);
  }

  logout(removeDeviceHash: boolean = false) {
    if (removeDeviceHash) {
      this.removeDeviceHash();
    }

    this.removeTokens();

    // ZendeskHelperService.hideWidget(); // TODO

    window.location.href = '/home';
  }

  static compressedToken: string = '';

  static setCompressedToken() {
    const token = localStorage.getItem(USER_TOKEN_KEY);
    this.compressedToken = '';

    if (token) {
      this.compressedToken = md5(token);
    }
  }

  static removeCompressedToken() {
    this.compressedToken = '';
  }

  static getMediaHashQuery() {
    return AuthService.compressedToken
      ? '?hash=' + AuthService.compressedToken
      : '';
  }
}
